<template>
  <mp-box
    id="qon-announcement-balance"
    as="div"
    :bg-color="bgColor"
    px="3"
    py="2"
  >
    <mp-flex justify-content="space-between">
      <mp-flex class="centered-item">
        <mp-icon
          name="warning-triangle"
          :color="bgColor === 'amber.100' ? 'dark' : 'white'"
          variant="fill"
          mr="3"
        />
        <mp-box font-weight="semibold" ml="2">
          <mp-text
            v-dompurify-html="bannerCreditBalance.message"
            as="span"
            :color="bgColor === 'amber.100' ? 'dark' : 'white'"
          />
        </mp-box>
      </mp-flex>
      <mp-box
        v-show="!isAgent() && !hideAction"
        as="div"
        @click="$emit('on-click-banner')"
      >
        <mp-button id="mp-button" variant="outline">
          {{
            billingInfo.status === 'expired' ||
            billingInfo.status === 'grace' ||
            isChatbot
              ? 'Customer support'
              : 'Top up quota'
          }}
        </mp-button>
      </mp-box>
    </mp-flex>
  </mp-box>
</template>

<script>
export default {
  name: 'BannerCreditBalance',
  props: {
    bannerCreditBalance: {
      type: Object,
      default: () => ({}),
    },
    billingInfo: {
      type: Object,
      default: () => ({}),
    },
    isChatbot: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: 'amber.100',
    },
    hideAction: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.onCheckBannerHeight()
  },
  methods: {
    onCheckBannerHeight() {
      const banner = document.querySelector('#qon-announcement-balance')
      banner &&
        this.$emit('set-banner-height', {
          type: 'credit-balance',
          value: banner.offsetHeight || 0,
        })
    },
  },
}
</script>
